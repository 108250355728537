import'./Glaciar.css'

import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";

import Navegation from "../../components/Navegation/Navegation";
import Footer from "../../components/Footer/Footer";



export default function Glaciar() {
  const {params}=useParams();
  let [show,setShow]=useState(false);
  let images=[
    {
      id:1,
      source:"/imagenes/Moreno/moreno2.jpg"
    }, {
      id:2,
      source:"/imagenes/Moreno/moreno3.jpg"
    }, {
      id:3,
      source:"/imagenes/Moreno/moreno4.jpg" 
    }, {
      id:4,
      source:"/imagenes/Moreno/moreno5.jpg"
    },{
      id:5,
      source:"/imagenes/Moreno/moreno6.jpg"
    }
  ]




    return(
        <Fragment>
        <img className='bg-mobile' src='/imagenes/fondo-sm.jpg' aria-hidden='true' alt=''/>
        <Navegation leng={params}  />
        <main >

        <h1>GLACIAR PERITO MORENO</h1>
      
          <div className="principal d-flex flex-column container">
              <p className="w-100 texto-p-aero text-uppercase fs-5 row py-5">
              {params === 'esp'? `Glaciar Perito Moreno se encuentra a 80 kilometros de El Calafate, los últimos 30 Kilometros dentro del Parque Nacional Los Glaciares es de un camino sinuoso.
                Este Servicio de Traslado inicia a la hora que el Pasajero lo crea conveniente se regresa cuando el pasajero ya haya cumplido con todas sus expectativas. Trabajamos sin limite de horario en todos nuestros Servicios, el encuentro es en el Hotel.`:`Perito Moreno Glacier is located 80 kilometers from El Calafate, the last 30 kilometers inside Los Glaciares National Park is a winding road.
                This Transfer Service starts at the time that the Passenger deems convenient and returns when the passenger has met all their expectations. We work without time limits in all our Services, the meeting is at the Hotel.`}</p>
                <p className="w-100 texto-p-aero text-uppercase fs-5 row pt-2">
                {params === 'esp'?  `Vamos a transitar bordeando El Lago Argentino que se encuentra  hacia la derecha, dentro del Parque lo tendremos a nuestra izquierda, el paisaje que nos va a acompañar es deslumbrante, la Estepa Patagonica y de frente la Cordillera de Los Andes, se hacen todas las paradas para sacar fotos que el pasajero lo requiera excepto dentro del Parque ya que no esta permitido.`:`We are going to travel bordering the Argentino Lake that is to the right, inside the Park we will have it on our left, the landscape that will accompany us is dazzling, the Patagonian Steppe and in front of the Andes Mountains, all the stops are made to take photos that the passenger requires except inside the Park since it is not allowed.`}</p>
                <p className="w-100 texto-p-aero text-uppercase fs-5 row pt-2">
                {params === 'esp'?` En el Parque se abonan las entradas correspondientes y dentro del mismo esta la opción de una navegación durante una hora que te acerca a la pared del Glaciar, lo cual se solicita en el momento y se abona en el lugar, los estaríamos acercando al Muelle para dicho paseo según el requerimiento de cada pasajero.`:`In the Park the corresponding entrances are paid and within it there is the option of a navigation for an hour that brings you closer to the Glacier wall, which is requested at the moment and paid on the spot, we would be bringing them closer to the Dock to said tour according to the requirement of each passenger.`}</p>
                <p className="w-100 texto-p-aero text-uppercase fs-5 row pt-2 pb-5">
                {params === 'esp'? `Lo primero que se alcanza a ver del Glaciar es la Punta Sur, en segundo lugar la Punta Norte y finalmente el centro o frente que seria el final del recorrido ya que ahí termina la ruta 11 que nos conduce a apreciar esta imponente MARAVILLA. En este lugar se encuentran servicios de Cafeteria y comida rápida en su defecto, esta permitido llevar LUNCH BOX.
                Este Servicio de traslado tiene una carga horaria de APROXIMADAMENTE 6 horas.`:`The first thing that can be seen of the Glacier is the Punta Sur, secondly the Punta Norte and finally the center or front, which would be the end of the route, since Route 11 ends there and leads us to appreciate this imposing MARVEL. In this place there are Cafeteria and fast food services, failing that, it is allowed to take LUNCH BOX.
                This Transfer Service has a workload of APPROXIMATELY 6 hours`}
                
              </p>
    
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81206.45493292957!2d-73.19990621554172!3d-50.50250904954366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbda4cacd0e5b0ee5%3A0x89e4ea723fbd70a7!2sPasarelas%20glaciar%20perito%20moreno!5e0!3m2!1ses!2sar!4v1635598086174!5m2!1ses!2sar" title="glaciar-perito-moreno-map" allowFullScreen="" loading="lazy"></iframe>
          </div>
      <section className="container py-5">
          <h2 className="row">{params === 'esp'? 'GALERIA DE FOTOS':"PHOTO GALLERY"}</h2>

          <div className="row gap-5 justify-content-center flex-wrap pb-5 max-80">
          {images.map((image)=>{
            return (
              <div key={image.id} className= "container-images-ch shadow-lg overflow-hidden p-0 position-relative" onClick={(event)=>{
                event.target.parentNode.classList.toggle("scale-img");
                setShow(!show);
                event.target.parentNode.children[0].classList.toggle("d-none");
                }}>
                <i className='bi bi-x-circle fs-3 position-absolute top-0 end-0 z-2 d-none'></i>
                  <p className={show === false?"position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none ":'position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none invisible'} >{params === 'esp'?'AMPLIAR IMAGEN':'ZOOM IN'}</p>
                  <img src={image.source}  className="h-100" alt="imagen galeria"/>
                </div>
                
                )
          
          })}
          </div>



       </section>
        
      </main>  

        <Footer/>
        </Fragment>
)}