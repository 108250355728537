import'./Chalten.css'
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";

import Navegation from "../../components/Navegation/Navegation";
import Footer from "../../components/Footer/Footer";



export default function Chalten() {
    const {params}= useParams();
      let [show,setShow]=useState(false);
      let images=[{
        id:1,
        source:"/imagenes/Chalten/img1.jpg"
      },{
        id:2,
        source:"/imagenes/Chalten/img2.jpg"
      } ,
        {
        id:3,
        source:"/imagenes/Chalten/img3.jpg"
      },{
        id:4,
        source:"/imagenes/Chalten/img4.jpg"
      },{
        id:5,
        source:"/imagenes/Chalten/img5.jpeg"
      },{
        id:6,
        source:"/imagenes/Chalten/img6.jpeg"
      }
      ]
    return(
        <Fragment>
        <img className='bg-mobile' src='/imagenes/fondo-sm.jpg' aria-hidden='true' alt=''/>
        <Navegation leng={params} />

        <main>

            <h1 className="subtitle ">CHALTEN FULL DAY</h1>
  
            <div className="principal d-flex flex-column container ">
                <p  className="w-80 texto-p-aero text-uppercase fs-6 row py-5">
                    {params === 'esp'?
                    `La Capital del Trekking EL CHALTEN, se encuentra a 220 kilometros de Calafate, dentro del Parque Nacional Los Glaciares pero en este caso no se abona entrada.
                    No contamos con un horario de salida ni de llegada, nos ajustamos a los requerimientos de los pasajeros, pero en lo posible priorizamos salir  muy temprano pero con luz para poder apreciar la Flora y Fauna del recorrido. Tenemos miradores donde podemos parar a sacar fotos o diferentes momentos en donde amerita detenernos para apreciar el momento y por supuesto sacar fotos. A la mitad del recorrido nos detenemos en el famoso Punto Historico LA LEONA, ahí tenemos la posibilidad de tomar un cafe, comer algo y por supuesto informarnos del porque ese punto es Historico, todo esto teniendo en frente el Rio La Leona.`:` The Capital of Trekking EL CHALTEN, is located 220 kilometers from Calafate, within the Los Glaciares National Park but in this case there is no entrance fee.
                    We do not have a departure or arrival time, we adjust to the requirements of the passengers, but as far as possible we prioritize leaving very early but with light to be able to appreciate the Flora and Fauna of the route. We have viewpoints where we can stop to take photos or different moments where it is worth stopping to appreciate the moment and of course take photos. Halfway through the tour we stop at the famous LA LEONA Historic Point, there we have the possibility to have a coffee, eat something and of course find out why that point is Historic, all this having the La Leona River in front of us.`}
                 </p>
                 <p className='w-80 texto-p-aero text-uppercase fs-6 row pb-5'>  {params === 'esp'? `
                    Continuamos y comenzamos a apreciar el majestuoso FITZ ROY, CERRO TORRE…Casi al final del recorrido nos encontramos con el Glaciar mas grande que tenemos sobre esta zona, El Glaciar Viedma y su imponente Lago Viedma.
                    Luego se puede apreciar el hermoso Pueblo en medio de montañas desde donde nace el hermoso cordon montañoso EL FITZ ROY también llamado EL CHALTEN.
                    En este punto comienzan diferentes caminatas, muchas de ellas inician en diferentes lugares y el vehiculo los acercara a cada uno de ellos de acuerdo a su elección. Puede ser  Laguna de los tres - Laguna Capri - Cerro Torre - Mirador de Condores y Aguilas - Chorrillo del Salto. Ni bien terminan las actividades propuestas regresamos a Calafate. `:`We continue and begin to appreciate the majestic FITZ ROY, CERRO TORRE... Almost at the end of the tour we find the largest Glacier we have in this area, El Glaciar Viedma and its imposing Lake Viedma.
                    Then you can see the beautiful town in the middle of mountains from where the beautiful mountain range EL FITZ ROY also called EL CHALTEN is born.
                    At this point different walks begin, many of them start in different places and the vehicle will bring you closer to each of them according to your choice. It can be Laguna de los tres - Laguna Capri - Cerro Torre - Mirador de Condores y Aguilas - Chorrillo del Salto. As soon as the proposed activities finish we return to Calafate.`}
                </p>
                
                <p className="w-80 texto-p-aero text-uppercase fs-6 row pb-5">
                <strong className='fs-5 '>{params === 'esp'? `RECOMENDACIONES`:`RECOMMENDATIONS`}</strong>
                {params === 'esp'? `Calzado comodo en lo posible de Trekking.
                Si va a hacer un trekking de muchas horas llevar comida y agua.
                Protector solar.` :`Comfortable footwear for Trekking as much as possible.
                If you are going to trek for many hours, bring food and water.
                Sunscreen.`}
                </p>
                <p className="w-80 texto-p-aero text-uppercase fs-6 row pb-5">
                {params === 'esp'?`Este Servicio de traslado tiene una carga horaria de APROXIMADAMENTE 14 horas.`:`This Transfer Service has a workload of APPROXIMATELY 14 hours.`}
                </p>
        
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20802.294165227162!2d-72.90729817375372!3d-49.327789301616846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdbd03fe92914231%3A0xc4be5aa754aefc!2sEl%20Chalten%2C%20Santa%20Cruz!5e0!3m2!1ses!2sar!4v1635598007018!5m2!1ses!2sar" allowFullScreen="" loading="lazy" title="chalten-map"></iframe>
                </div>
             <section className='d-flex flex-column align-items-center'>
                <h2 className="subtitle ">{params === 'esp'? 'GALERIA DE FOTOS':"PHOTO GALLERY"}</h2>
                <div className="row gap-5 justify-content-center flex-wrap pb-5 max-80">
                {images.map((image)=>{
                return (
                    <div key={image.id} className= "container-images-ch shadow-lg overflow-hidden p-0 position-relative" onClick={(event)=>{
                    event.target.parentNode.classList.toggle("scale-img");
                    setShow(!show);
                    event.target.parentNode.children[0].classList.toggle("d-none");
                    }}>
                    <i className='bi bi-x-circle fs-3 position-absolute top-0 end-0 z-2 d-none'></i>
                        <p className={show === false?"position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none ":'position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none invisible'} >{params === 'esp'?'AMPLIAR IMAGEN':'ZOOM IN'}</p>
                        <img src={image.source}  className="h-100" alt="imagen galeria"/>
                    </div>
                    
                    )
                
                })}
                </div>
            </section>
        </main>  
        <Footer/>
        </Fragment>
)}