import "./MiniTrakking.css";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";

import Navegation from "../../components/Navegation/Navegation";
import Footer from "../../components/Footer/Footer";



export default function MiniTrakking() {
  const {params}= useParams();
  let [show,setShow]=useState(false);
  let images=[{
    id:1,
    source:"/imagenes/Minitrekking/img1.jpg"
  },{
    id:2,
    source:"/imagenes/Minitrekking/img2.jpg"
  }]

    return(
        <Fragment>
        <img className='bg-mobile' src='/imagenes/fondo-sm.jpg' aria-hidden='true' alt=''/>
        <Navegation leng={params}  />
        <main>

        <h1 className="subtitle ">MINITREKKING</h1>
      
          <div className="principal d-flex flex-column justify-content-between align-items-center container">
              <p className="w-80 texto-p-aero text-uppercase fs-5 row pt-5">
            {params === 'esp'   ? ` Para este Servicio de Traslado coordinamos con el pasajero el horario de salida del hotel de acuerdo al horario que cada pasajero tiene pautado en base a lo que previamente contrataron en la Compañía que tiene la concesión de dicha excursión…MINITREKKING.`:`For this Transfer Service we coordinate with the passenger the departure time from the hotel according to the schedule that each passenger has scheduled based on what they previously contracted with the Company that has the concession for said excursion…MINITREKKING.`}</p>
            <p className="w-80 texto-p-aero text-uppercase fs-5 row pt-5">
            {params === 'esp'   ? `  El recorrido es el mismo que el del Glaciar Perito Moreno, llevamos los pasajeros hasta el Muelle dentro del Parque previo pagar las entradas, el vehiculo permanece ahí las 5 horas que tardan para regresar al mismo punto después de caminar sobre EL Glaciar Perito Moreno. Ni bien nos reencontramos transitamos 8 kilometros mas hasta el final del recorrido donde encuentran servicio de cafetería y comidas rapidas, ahí tienen tiempo libre para recorrer en su totalidad todo el frente del Glaciar caminando sobre las pasarelas. Cuando el pasajero lo encuentra pertinente emprendemos nuestro regreso al Hotel.`:`The route is the same as that of the Perito Moreno Glacier, we take the passengers to the Pier inside the Park after paying the entrance fees, the vehicle remains there for the 5 hours it takes to return to the same point after walking on the Perito Moreno Glacier. As soon as we meet again, we travel 8 kilometers more until the end of the route where they find cafeteria service and fast food, there they have free time to explore the entire front of the Glacier walking on the catwalks. When the passenger finds it pertinent we start our return to the Hotel.`}</p>
            <p className="w-80 texto-p-aero text-uppercase fs-5 row pt-5">
            {params === 'esp'   ? ` Este Servicio de Traslado es recomendable en Privado para aquellas personas que cuentan con poco tiempo y para las que prefieren ahorrar una entrada al Parque, hacen dos en uno ya que pueden recorrer las pasarelas sin limite de horario. Por lo tanto deben contratar la Excursion MINITREKKING sin servicio de traslado.`:`This Transfer Service is recommended in Private for those people who have little time and for those who prefer to save a ticket to the Park, do two in one since they can travel the catwalks without time limits. Therefore, they must contract the MINITREKKING Excursion without transfer service.`}</p>
              <p className="w-80 texto-p-aero text-uppercase fs-5 row pt-5">   {params === 'esp'   ?`Este Servicio de traslado tiene una carga horaria de APROXIMADAMENTE 11 horas.`:`This Transfer Service has a workload of APPROXIMATELY 11 hours.`}</p>
                <p className="w-80 texto-p-aero text-uppercase fs-4 fw-bold row pt-5">
                {params === 'esp'   ? `Recomendaciones:` :`Recommendations:`}  </p>
                <ul className="pt-3 w-80 d-grid list-recomendations text-uppercase align-items-start">
                  <li>{params === 'esp' ?`Calzado comodo en lo posible de Trekking.`:` Comfortable footwear for Trekking as much as possible.`}</li>
                  <li><p>{params === 'esp' ?`Llevar el almuerzo.`:` Bring lunch.`}</p></li>
                  <li>{params === 'esp' ?`Guantes.`:`Gloves.`}</li>
                  <li>{params === 'esp' ?`Protector solar.`:`Sunscreen.`}</li>
                  <li>{params === 'esp' ?`Anteojos de sol.`:`Sunglasses.`}</li>
                </ul>
             
      
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d81206.45493292957!2d-73.19990621554172!3d-50.50250904954366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbda4cacd0e5b0ee5%3A0x89e4ea723fbd70a7!2sPasarelas%20glaciar%20perito%20moreno!5e0!3m2!1ses!2sar!4v1635598086174!5m2!1ses!2sar"  title="minitrakking-map" loading="lazy"></iframe>
          </div>
      <section>
          <h2 className="subtitle ">{params === 'esp'? 'GALERIA DE FOTOS':"PHOTO GALLERY"}</h2>
          <div className="row gap-5 justify-content-center flex-wrap pb-5 ">
          {images.map((image)=>{
            return (
              <div key={image.id} className= "container-images-ch shadow-lg overflow-hidden p-0 position-relative" onClick={(event)=>{
                event.target.parentNode.classList.toggle("scale-img");
                setShow(!show);
                event.target.parentNode.children[0].classList.toggle("d-none");
                }}>
                <i className='bi bi-x-circle fs-3 position-absolute top-0 end-0 z-2 d-none'></i>
                  <p className={show === false?"position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none ":'position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none invisible'} >{params === 'esp'?'AMPLIAR IMAGEN':'ZOOM IN'}</p>
                  <img src={image.source}  className="h-100" alt="imagen galeria"/>
                </div>
                
                )
          
          })}
          </div>
        </section>
      </main>  

        <Footer/>
        </Fragment>
)}