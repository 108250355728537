import './Aeropuerto.css'

import { Fragment ,useState} from "react";
import { useParams } from "react-router-dom";



import Navegation from "../../components/Navegation/Navegation";
import Footer from "../../components/Footer/Footer";


export default function Aeropuerto() {
  let { params } = useParams();
  let [show,setShow]=useState(false);
  let images=[
   {id:1,
    source:" /imagenes/aeropuerto/aeropuerto1.jpeg"
  },
   {id:2,
    source:"/imagenes/aeropuerto/aeropuerto2.jpeg"
  }
  ];

    return(
    <Fragment>
    <img className='bg-mobile' src='/imagenes/fondo-sm.jpg' aria-hidden='true' alt=''/>
        <Navegation leng={params}/>

        <main>

        <h1 className="subtitle " >{params === 'esp'?'AEROPUERTO IN/OUT':'AIRPORT IN/OUT'}</h1>

      <div className="principal d-flex flex-column container">
        <p className="w-80 texto-p-aero text-uppercase fs-5 row py-5">
        {params === 'esp'? `IN Aeropuerto…El aeropuerto Armando Tola de El Calafate se encuentra a 23 kilometros de la Ciudad, para este Servicio de Traslado los recibimos con un cartel de bienvenida y nos dirigimos directamente a su Hotel o lugar donde se hospeda.
            OUT Aeropuerto…Los buscamos por su Hotel dos horas antes del vuelo.`:`IN Airport…The Armando Tola airport in El Calafate is located 23 kilometers from the City, for this Transfer Service we receive you with a welcome sign and we go directly to your Hotel or place where you are staying.
            OUT Airport… We look for you at your Hotel two hours before the flight.`}
        </p>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2549.4259144373477!2d-72.05583464864121!3d-50.28397697935195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdbb9f5e2caeee93%3A0x6ba235b70aaa63e9!2sAeropuerto%20Internacional%20Comandante%20Armando%20Tola%20de%20El%20Calafate!5e0!3m2!1ses-419!2sar!4v1635589597302!5m2!1ses-419!2sar"   allowFullScreen="" loading="lazy" title="aeropuerto-map"></iframe>
    </div>

    <section className="container py-5 pb-5">
    
      <h2 className="row">{params === 'esp'? 'GALERIA DE FOTOS':"PHOTO GALLERY"}</h2>
      <div className="row gap-5 justify-content-center flex-wrap pb-5 ">
      {images.map((image)=>{
        return (
          <div key={image.id} className= "container-images shadow-lg overflow-hidden p-0 position-relative" onClick={(event)=>{
            event.target.parentNode.classList.toggle("scale-img");
            setShow(!show);
            event.target.parentNode.children[0].classList.toggle("d-none");
            }}>
            <i className='bi bi-x-circle fs-3 position-absolute top-0 end-0 z-2 d-none'></i>
              <p className={show === false?"position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none ":'position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none invisible'} >{params === 'esp'?'AMPLIAR IMAGEN':'ZOOM IN'}</p>
              <img src={image.source}  className="h-100" alt="imagen galeria"/>
            </div>
            
            )
      
      })}
      </div>
    </section>
  
</main>  
        <Footer/>
        </Fragment>
)}