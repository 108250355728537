import "./Footer.css"

export default function Footer() {
    return(
        <footer className="w-100 text-center d-flex ">
        <div className="redes d-flex flex-column ">
            <a href="https://wa.me/542966676986/?text=buen dia,quiero hacer una consulta%20" className="text-decoration-none"><i className="fab fa-whatsapp "></i> +54 2966676986</a>
            <a href="https://wa.me/542966676986/?text=buen dia,quiero hacer una consulta%20" className="text-decoration-none"><i className="far fa-envelope "></i> livingcalafate@gmail.com</a>
            <a href="https://www.instagram.com/livingcalafate/" className="text-decoration-none"><i className="bi bi-instagram"></i>Livingcalafate</a>
        
        </div> 
        <div className="location">
            <a href='https://www.freepik.es/vectores/acuarela' className="text-decoration-none">Vector de Acuarela creado por freepik - www.freepik.es</a>
            <p>Copyright © Victoria Lopez - 2021</p>
        </div>
    
        
    </footer>
    )}