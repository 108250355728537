import'./Contact.css'
import{Fragment,useState} from 'react'


export default function Contact({leng}){
    const[okMesage,setOkMesage]=useState(false);

    const sendEmail=async(event)=>{
        event.preventDefault();
     
         const form= new FormData(event.target);
         console.log(form)
        await fetch("https://formspree.io/f/maygekko" ,{
            method:"POST",
            body:form,
            headers:{
                "Accept":'application/json'
            }
        })
        .then(()=> {setOkMesage(true);setTimeout(()=>{setOkMesage(false)},5000)})
        .catch((err)=>console.log(err))
            
    };

    return(
    <Fragment>
        <section id="contact-us" className='d-flex flex-column justiyf-content-center align-items-center text-uppercase'>

            <a className="f-a-style text-decoration-none text-center btn btn-outline-primary w-50" href="https://wa.me/541136332941/?text=buen dia,quiero hacer una consulta%20" target="_blank" rel="noreferrer"> 
                <h3 className='fs-3'>
                    <i className="fab fa-whatsapp fs-5 pe-2"></i>
                   { leng === 'esp'?'Mandanos tu mensaje por Whatsapp':'Send us your message by Whatsapp'}
                </h3>
            </a>

            <div className='d-flex w-75 p-5 align-items-center justify-content-between line-area'>
                <hr className='line opacity-100'/>
                <p className='m-0 text-center'>{ leng === 'esp'? 'o sino tambien':'or else also'}</p>
                <hr className='line opacity-100'/>
            </div>
            
            <h3>{ leng === 'esp'?'Mandanos un correo':'Send us an email'}</h3>
        {okMesage === false?
            <form className="form d-flex flex-column" onSubmit={(event)=>sendEmail(event)} action="https://formspree.io/f/maygekko" method='POST' >
                <label htmlFor="email">
                    Email
                <input className="input-style" name="email" type="email" id='email'/> 
                </label>
                <label htmlFor="nameContact">
                { leng === 'esp'? 'Nombre':'Name'}
                    <input className="input-style" name="nombre" type="text" id='nameContact'/> 
                </label>
                <label htmlFor="mesage">
                { leng === 'esp'? 'Mensaje':'message'}
                    <textarea className="input-style" name="mensaje" id='mesage'></textarea>
                </label>
                <input  type='submit' className="btn btn-send" value={ leng === 'esp'? 'ENVIAR':'SEND'}/>
                
            </form>
        :
            <div className='alert-mesage row p-5 w-25 rounded'>
                <p className='text-center fs-5 text-white text-popins'>{ leng === 'esp'? 'Mensaje enviado correctamente':'Message sent successfully'}</p>
            </div>
        }
      
        </section>
    </Fragment>
    )}