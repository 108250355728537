import'./Home.css';
import { Fragment,useEffect,useState } from "react";

import ModalLenguaje from "../ModalLenguaje/ModalLenguaje";
import Navegation from "../../components/Navegation/Navegation";
import Footer from "../../components/Footer/Footer";
import Card from "../../components/Card/Card";
import Contact from "../../components/Contact/Contact";
import Vehicle from "../../components/Vehicle/Vehicle";
import ThanksSeccion from "../../components/ThanksSeccion/ThanksSeccion";
import dataEsp from '../../dato.json';
import dataIngl from '../../dato-engl.json';


export default function Home() {
    const[cards,setCards]=useState([]);
    const[lenguaje,setLenguaje]=useState('');
    const[lengClose,setLengClose]=useState(false);
   




    useEffect(()=>{
      let data= sessionStorage.getItem("lang");
      setLenguaje(data)
      if(data === 'eng'){
        setCards(dataIngl)
        return 
      }
      if(data === "esp"){
            setCards(dataEsp)  
          return 
      }
    },[lengClose])



    return(
      <Fragment>
        {lenguaje === null ?<ModalLenguaje setLengClose={setLengClose}/>:
      <div >
      <img className='bg-mobile' src='./imagenes/fondo-sm.jpg' aria-hidden='true' alt=''/>
        <Navegation leng={lenguaje}/>
            <main >
                <section className='containter-fluid carousel-home position-relative'>
                <div id="carouselHome" className="carousel slide row" data-bs-ride="carousel">
                        <div className="carousel-inner ">
                          <div className="carousel-item active col" >
                              <img  src="./imagenes/principales/imagen-princ1.jpeg" className="d-block  w-100 img-carousel" alt="imagen panoramica calafate"/>
                          </div>
                          <div className="carousel-item col" >
                              <img  src="./imagenes/principales/imagen-princ3.jpeg" className="d-block  w-100 img-carousel" alt="imagen panoramica calafate"/>
                          </div>
                          <div className="carousel-item col" >
                              <img  src="./imagenes/principales/imagen-princ2.jpeg" className="d-block  w-100 img-carousel" alt="imagen panoramica calafate"/>
                          </div>
     
                        </div>
                    </div>
                    <div className="contenido d-flex justify-content-center flex-column align-items-center z-1 bottom-0 py-2 position-absolute w-100">
                        <h1 className='title-h1'>Living-Calafate</h1>
                        <p className='text-home text-center'> 
                          {lenguaje === 'esp'? 'UN DESTINO QUE TE IMPACTA!!':'A DESTINATION THAT IMPACTS YOU'}
                        </p>
                        <p className='text-home text-center '>
                        {lenguaje === 'esp'?'Vivimos en este hermoso lugar, te acompañamos a que lo':'We live in this beautiful place, we accompany you to'} 
                        <span className='ps-1'>
                        {lenguaje === 'esp'? 'VIVAS CON NOSOTROS.':'LIVE IT  WITH US.'}
                        </span>
                        </p>
                        <div className='align-self-end pe-5 d-flex align-items-end'>
                          <p className='pe-1'> {lenguaje === 'esp'? "Con la certificacion de": "With the certification of "} </p>
                          <img className='tehuelche' src='./imagenes/tahuelche.png' alt='remiseria tehuelche'/>  
                        </div>
                        
                    </div> 
            </section>    
          <section className='section-space' id="destinos">
            <h2 className="destino subtitle" >
            {lenguaje === 'esp'? 'NUESTROS DESTINOS':"OUR DESTINATIONS"}</h2>

            <div className="cards-position">
              {cards.map((card)=>{
                return <Card key={card.id}titulo={card.titulo} intro={card.intro} imagenFront={card.imagenFront} imagenBack={card.imagenBack} rutaL={card.rutaL} leng={lenguaje}/> })
              }
            </div>
          </section>
            
            <section className="emotion section-space" id="contacto">
                <h2 className="subtitle" >
                {lenguaje === 'esp'? 'CONTACTO':'CONTACT'}</h2>
                <Contact  leng={lenguaje}/>
            </section>

            <section className="us section-space" id="nosotros">
                <h2>
                {lenguaje === 'esp'? 'SOBRE NOSOTROS':"ABOUT US"}</h2>
                    <p className="text-about-us text-uppercase">
                    {lenguaje === 'esp'? 'Vivimos en el calafate desde el año 2000. Somos prestadores de servicios, solo traslados en privado. Nos dedicamos a esto desde el año 2007 con mucho cariño y disfrutamos de nuestro trabajo tanto como los visitantes que vienen a vivir esta experiencia':'We have lived in El Calafate since 2000. We are service providers, only private transfers. We have been dedicated to this since 2007 with great affection and we enjoy our work as much as the visitors who come to live this experience.'}
                            
                    </p>
            </section>
            
            <section className="emotion1 section-space">    
                <h2 className=" nuestrosV"> {lenguaje === 'esp'? 'NUESTROS VEHICULOS':'OUR VEHICLES'}</h2>
              
                <Vehicle  leng={lenguaje} />
            

            </section>
            <h2 className='section-space subtitle nuestrosV w-100 '>
            {lenguaje === 'esp'?'AGRADECIMIENTOS':'GREATINGS'}</h2>
            <article className='text-uppercase container padding-bottom'>
              <ThanksSeccion  leng={lenguaje}/>
            </article>
            

            </main>
        <Footer/>
        </div>}
      </Fragment>
    )}  