import{BrowserRouter,Routes,Route} from 'react-router-dom';
import Home from '../pages/Home/Home';
import Aeropuerto from '../pages/Aeropuerto/Aeropuerto';
import Chalten from '../pages/Chalten/Chalten';
import Glaciar from '../pages/Glaciar/Glaciar';
import MiniTrakking from '../pages/MiniTrakking/MiniTrakking';
import PuntaBandera from '../pages/PuntaBandera/PuntaBandera';

export default function Router(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path='/Aeropuerto/:params' element={<Aeropuerto/>}/>
                <Route path='/Chalten/:params' element={<Chalten/>}/>
                <Route path='/Glaciar/:params' element={<Glaciar/>}/>
                <Route path='/Minitrakking/:params' element={<MiniTrakking/>}/>
                <Route path='/PuntaBandera/:params' element={<PuntaBandera/>}/>
            </Routes>
        </BrowserRouter>
    )
}