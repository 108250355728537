import{Link} from 'react-router-dom';
import { useEffect, useState } from 'react';
import "./Navegation.css"

export default function Navegation({leng}) {
    const[desplegar,setDesplegar]= useState(false);
    const[navFixed,setNavFixed] = useState('');

    useEffect(()=>{
        if(desplegar === true){
            setNavFixed(' position-sticky top-0 z-5 bg-small')
        }else{
            setDesplegar('')
        }
    },[desplegar])

return(
    <nav className={"d-flex justify-content-between align-items-center container nav-container" + navFixed}>
   
        <Link to="/" className="logo_item w-25"><img className="logo-img" src="/imagenes/logo.png" alt="logo"/></Link>
        <i className={ desplegar === true?"bi bi-x-lg hamb my-auto":"bi bi-list hamb"} onClick={()=>{setDesplegar(!desplegar)}}></i>
        { leng !== 'eng'?
        <ul className={desplegar === true?"nav  d-flex" :"nav"}  >   
            <li className="nav-item deco-items"><a href="/#destinos" onClick={()=>{setTimeout(setDesplegar(false),2000)}} className="nav-item-link text-uppercase text-decoration-none" >Destinos</a></li>
            <li className="nav-item deco-items"><a href="/#contacto" onClick={()=>{setTimeout(setDesplegar(false),2000)}} className="nav-item-link text-uppercase text-decoration-none ">Contacto</a></li>
            <li className="nav-item deco-items"><a href="/#nosotros" onClick={()=>{setTimeout(setDesplegar(false),2000)}} className="nav-item-link text-uppercase text-decoration-none">Sobre Nosotros</a></li>
        </ul>   
    :
        <ul className="nav " >   
            <li className="nav-item deco-items"><a href="/#destinos" onClick={()=>{setTimeout(setDesplegar(false),2000)}} className="nav-item-link text-uppercase text-decoration-none" >Destinations</a></li>
            <li className="nav-item deco-items"><a href="/#contacto" onClick={()=>{setTimeout(setDesplegar(false),2000)}} className="nav-item-link text-uppercase text-decoration-none ">Contact</a></li>
            <li className="nav-item deco-items"><a href="/#nosotros" onClick={()=>{setTimeout(setDesplegar(false),2000)}} className="nav-item-link text-uppercase text-decoration-none">About Us</a></li>
        </ul>   
    
    }
 

    </nav>
)}