import "./PuntaBandera.css";
import { Fragment, useState } from "react";
import { useParams } from "react-router-dom";

import Navegation from "../../components/Navegation/Navegation";
import Footer from "../../components/Footer/Footer";



export default function PuntaBandera() {
  const {params}= useParams();
  let [show,setShow]=useState(false);
  let images=[{
    id:1,
    source:"/imagenes/Punta-bandera/img1.jpg"
  },{
    id:2,
    source:"/imagenes/Punta-bandera/img2.jpg"
  },{
    id:3,
    source:"/imagenes/Punta-bandera/img3.jpg"
  },{
    id:4,
    source:"/imagenes/Punta-bandera/img4.jpg"
  }];

    return(
        <Fragment>
        <img className='bg-mobile' src='/imagenes/fondo-sm.jpg' aria-hidden='true' alt=''/>
        <Navegation leng={params}  />
        <main>

            <h1 className="subtitle ">PUERTO PUNTA BANDERA</h1>
        
            <div className="principal d-flex flex-column container ">
                <p className="w-80 texto-p-aero text-uppercase fs-6 row pt-5" >
                   {params === 'esp'? `El puerto se encuentra a 50 kilometros de El Calafate, el horario de encuentro por el Hotel es 7 15 y el regreso será de acuerdo al horario de llegada de la Excursion previamente contratada por el pasajero.
                    En el Puerto se abona la entrada al Parque, y los barcos los llevan a conocer otros Glaciares que solo se pueden apreciar por este medio GLACIAR UPSALA, GLACIAR SPEGAZZINI.`:`The port is located 50 kilometers from El Calafate, the meeting time at the Hotel is 7:15 a.m. and the return will be according to the arrival time of the Excursion previously contracted by the passenger.
                    In the Port you pay the entrance to the Park, and the boats take you to see other Glaciers that can only be appreciated by this means UPSALA GLACIER, SPEGAZZINI GLACIER.`}</p>
                    <p className="w-80 texto-p-aero text-uppercase fs-6 row pt-5" >
                   {params === 'esp'? `
                    Este Servicio de traslado tiene una carga horaria de APROXIMADAMENTE 8 horas.`:`This Transfer Service has a workload of APPROXIMATELY 8 hours.`}</p>
                    

                    <p className="w-80 texto-p-aero text-uppercase fs-4 fw-bold row pt-5" >
                    {params === 'esp'? `
                     NOTA IMPORTANTE`:`IMPORTANT NOTE`}</p>
                    <p className="w-80 texto-p-aero text-uppercase fs-6 row pb-5" >
                   {params === 'esp'? `Todo aquel pasajero que desee luego de Navegar continuar en dirección hacia el Glaciar Perito Moreno por tierra 30 kilometros mas debe solicitar este Servicio con anticipación. Es habitual que pasajeros lo soliciten por falta de tiempo o para ahorrar una entrada al Parque ya que le sirve la que abono durante la mañana.
                    Pasajeros que contraten Glaciares Gourmet y deseen desembarcar en el Glaciar Perito Moreno deben informar previamente a la Compañía MARPATAG, y solicitarnos con anticipación el encuentro en EL Glaciar Perito Moreno, eso les va a permitir hacer dos Excursiones en uno y ahorrar una entrada al Parque.`
                    :` Any passenger who wishes to continue in the direction of the Perito Moreno Glacier by land for 30 kilometers more after sailing must request this service in advance. It is common for passengers to request it due to lack of time or to save an entrance fee to the Park, since the one paid for in the morning is good for them.
                    Passengers who hire Gourmet Glaciers and wish to disembark at the Perito Moreno Glacier must previously inform the MARPATAG Company, and ask us in advance to meet at the Perito Moreno Glacier, this will allow them to do two Excursions in one and save an entrance fee to the Park.`}</p>
        
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10194.133558401754!2d-72.79002521183581!3d-50.30063741334079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xbdbb44110006ded5%3A0x16cd62c2ce59ae07!2sPuerto%20Bandera%2C%20Santa%20Cruz!5e0!3m2!1ses!2sar!4v1635598208169!5m2!1ses!2sar" title="punta-bandera-map" allowFullScreen="" loading="lazy"></iframe>
            </div>
        <section>
            <h2 className="subtitle ">{params === 'esp'? 'GALERIA DE FOTOS':"PHOTO GALLERY"}</h2>
            <div className="row gap-5 justify-content-center flex-wrap pb-5 ">
            {images.map((image)=>{
              return (
                <div key={image.id} className= "container-images-ch shadow-lg overflow-hidden p-0 position-relative" onClick={(event)=>{
                  event.target.parentNode.classList.toggle("scale-img");
                  setShow(!show);
                  event.target.parentNode.children[0].classList.toggle("d-none");
                  }}>
                  <i className='bi bi-x-circle fs-3 position-absolute top-0 end-0 z-2 d-none'></i>
                    <p className={show === false?"position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none ":'position-absolute w-100 h-100 text-white fs-4 text-img text-center d-none invisible'} >{params === 'esp'?'AMPLIAR IMAGEN':'ZOOM IN'}</p>
                    <img src={image.source}  className="w-100" alt="imagen galeria"/>
                  </div>
                  
                  )
            
            })}
            </div>
          </section>
        </main>  

        <Footer/>
    </Fragment>
)}