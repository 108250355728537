import{Link} from 'react-router-dom';
import './Card.css'


export default function Card({titulo,intro,imagenFront,imagenBack,rutaL,leng}) {
      
return(<section>
    <div className="flip-container " onTouchStart={(event)=>event.target.classList.toggle('hover')} >
          <div className="flipper" >
                <div className="front d-flex justify-content-center align-items-center position-absolute top-0 start-0" style={{backgroundImage:`url(${imagenFront})`, backgroundSize: 'cover'}}>
                {/* front content */}
                    
                    <h3 className='title-card'>{titulo}</h3>
                </div>
                <div className="back position-absolute top-0 start-0 d-flex flex-column justify-content-around align-items-end" style={{backgroundImage:`url(${imagenBack})`, backgroundSize: 'cover'}}>
                      {/* back content */}
                    
                      <p className="resume w-100 font-p">{intro}</p>
                      <div className="locating d-flex w-100 justify-content-end">
                            <a href="#contact-us" className='text-decoration-none text-uppercase btn-card'>{leng === 'esp'? 'Contactanos':'Contact Us'}</a>
                           {titulo === "TRANSFER TO EL CHALTEN" || titulo === "TRASLADO A EL CHALTEN" ?'': <Link to={rutaL+leng} className='text-decoration-none text-uppercase btn-card'>{leng === 'esp'? 'Mas info':'More info'}</Link> }             
                      </div>

                </div>
          </div>

    </div>    


</section>)
}
