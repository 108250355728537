import './ThanksSeccion.css';
import { Fragment } from "react";

export default function ThanksSeccion({leng}) {
    return(
        <Fragment>
            <p className="row text-thanks text-center">{leng === 'esp'? 'Agradecemos a todos aquellos que confiaron en nosotros a lo largo de todos estos años, con Ustedes hemos disfrutado, admirado cada uno de los lugares donde nos toco visitar.':'We thank all those who trusted us throughout all these years, with you we have enjoyed and admired each of the places where we had to visit.'}
            </p>
            <p className="row text-thanks justify-content-end
            ">Living-Calafate</p>
        </Fragment>
    )
}