import "./ModalLenguaje.css";
import {useState} from 'react';



export default function ModalLenguaje({setLengClose}) {
    const[close,setClose]=useState(false);

    const changeLeng=(info)=>{
        sessionStorage.setItem("lang",info)
        setLengClose(true)
            return setClose(true)
       
    }

    return(
        <div className={close === false?"fondo-modal center":" hide"}>
            <div className="card-modal w-50 h-50">
                <ul className="options-lenguaje center w-100 h-100">
                    <li>
                        <button className="items-card" onClick={()=>{changeLeng("esp")}}>Español</button>
                    </li>
                    <li>
                        <button className="items-card" onClick={()=>{changeLeng("eng")}}>English</button> 
                    </li>
                </ul>
                
               
            </div>
        </div>
    )
}