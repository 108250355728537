import { Fragment,useState} from 'react';
import './Vehicle.css'

export default function Vehicles({leng}) {

  const [playVideo,setPlayVideo ]=useState(true)


    return(
      <Fragment>
        <div className='d-flex flex-column'>
        <div className='position-relative' onClick={()=>{setPlayVideo(!playVideo)}}>
            <video src='./video/VN20230929_211353.mov' muted={playVideo} autoPlay playsInline
              loop className=' mb-5 w-100'>
            </video>       
           {playVideo? <i className="bi bi-volume-up-fill fs-1 position-absolute text-white bottom-vol end-0"></i> :<i className="bi bi-volume-mute-fill fs-1 position-absolute text-white bottom-vol end-0"></i>  }
        </div>
    
          <section className='row w-100'>
          <p className="text-uppercase text-center subtitle fs-4 text-white">{ leng === 'esp'? 'Contamos con vehículos de distintas capacidades:':'We have vehicles of different capacities:'}</p>

          <div className='d-flex justify-content-center px-0 w-100 gap-4 ps-4 section-vehicles'>
            <div className='pb-0 box-vehicles-type shadow-lg px-4 align-self-end '>
              <h3>MASTER</h3>
              <p className='text-white'>hasta 14 pasajeros</p>
            </div>
            <div className='pb-0  box-vehicles-type  shadow-lg  px-4 align-self-start' >
              <h3>H1 HIUNDAY</h3>
              <p className='text-white'>hasta 7 pasajeros</p>
            </div>
            <div className='pb-0 box-vehicles-type shadow-lg px-4 align-self-end'>
              <h3>SPINTER</h3>
              <p className='text-white'>hasta 18 pasajeros</p>
            </div>
            <div className='pb-0  box-vehicles-type shadow-lg px-4 align-self-start'>
              <h3>AUTO</h3>
              <p className='text-white'>hasta 4 pasajeros</p>
            </div>        
          </div>


          </section>        
        </div>


      </Fragment>
)}